<template xmlns:tbody="http://www.w3.org/1999/html">
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form data-vv-scope="maerskTaxesForm" method="post">
      <div class="row justify-content-md-center p-3">

        <div class="col-12 col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <!-- Número -->
                <custom-input
                  v-model="formData.numero"
                  :is-view="true"
                  name="formData.numero"
                  :label="this.$i18n.t('form.tarifario_lcl.numero')"
                  type="text"
                  rootClassName="col-12 col-sm-2 mb-4"
                  :max-length="75"
                  v-validate="{ required: true }"
                  :error="submitted = errors.first('formData.numero')">
                </custom-input>
                <!-- Tipo Operação -->
                <div class="col-12 col-sm-2 form-group mb-4">
                  <label>{{ this.$i18n.t('form.tarifario_lcl.tipo_operacao') }}</label>
                  <div>
                    {{formData.tipoOperacao.nome }}
                  </div>
                </div>

                <!-- Número Contrato -->
                <custom-input
                  v-model="formData.contratoFrete.numeroContrato"
                  :is-view="true"
                  name="formData.contratoFrete.numeroContrato"
                  :label="this.$i18n.t('form.tarifario_lcl.numero_contrato')"
                  type="text"
                  rootClassName="col-12 col-sm-8 mb-4"
                  :max-length="75"
                  v-validate="{ required: true }"
                  :error="submitted = errors.first('formData.contratoFrete.numeroContrato')">
                </custom-input>

                <!-- Armador -->
                <armador-field
                  v-model="formData.armador"
                  :is-view="true"
                  rootClassName="col-12 col-sm-10 form-group mb-4"
                />

                <!-- Ativo -->
                <div class="col-md-1 mb-4">
                  <label>{{$t('form.tarifario_lcl.ativo')}}</label>
                  <custom-switch
                    :listItems="[{text: '', value: 1}]"
                    :is-view="true"
                    v-model="formData.ativo"
                    name="formData.ativo"
                    type="checkbox"
                    stateClassName="p-primary"
                    :error="errors.first('formData.ativo')">
                  </custom-switch>
                </div>

                <!-- Visível -->
                <div class="col-md-1 mb-4">
                  <label>{{$t('form.tarifario_lcl.visivel')}}</label>
                  <custom-switch
                    :listItems="[{text: '', value: 1}]"
                    :is-view="true"
                    v-model="formData.visivel"
                    name="formData.visivel"
                    type="checkbox"
                    stateClassName="p-primary"
                    :error="errors.first('formData.visivel')">
                  </custom-switch>
                </div>

                <!-- Data Início Vigência -->
                <div class="col-12 col-md-3">
                  <label>{{ this.$i18n.t('form.tarifario_lcl.data_inicio_vigencia') }}</label>
                  <div>
                    {{ $util.formatDate(formData.dataInicioVigencia) }}
                  </div>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.dataInicioVigencia')"
                           class="royalc-error-field">{{ errors.first('formData.dataInicioVigencia') }}</small>
                  </transition>
                </div>

                <!-- Data Validade -->
                <div class="col-12 col-md-3">
                  <label>{{ this.$i18n.t('form.tarifario_lcl.data_validade') }}</label>
                  <div>
                    {{ $util.formatDate(formData.dataValidade) }}
                  </div>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.dataValidade')"
                           class="royalc-error-field">{{ errors.first('formData.dataValidade') }}</small>
                  </transition>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="col-12 col-md-12">
          <!--  Configurações -->
          <div class="card">
            <div class="card-body">
              <h5 class="box-title mb-3">{{ $t('form.tarifario_lcl.section.rota') }}</h5>
              <div class="row mb-5" v-for="(rota, index) in formData.rotas" v-bind:key="index">
              <!-- Origem-->
              <origin-destination-field :show-expo="(formData.tipoOperacao && formData.tipoOperacao.id === 1 )? true : false"
                                        :show-impo="(formData.tipoOperacao && formData.tipoOperacao.id === 1 )"
                                        :is-view="true"
                                        :grey-field="false"
                                        root-class-name="col-12 col-lg-4 col-md-6 form-group px-2"
                                        v-model="rota.origem"
                                        :name="'rota.origem' + index"
                                        v-validate="'required_object'"
                                        :error="errors.first('rota.origem' + index)"
              />

              <!--  Destino  -->
              <origin-destination-field :show-expo="(formData.tipoOperacao && formData.tipoOperacao.id === 1 )? false : true"
                                        :grey-field="false"
                                        :is-view="true"
                                        :show-impo="(formData.tipoOperacao && formData.tipoOperacao.id === 1 )? false : true"
                                        root-class-name="col-12 col-lg-4 col-md-6 form-group px-2"
                                        v-model="rota.destino"
                                        :name="'rota.destino' + index"
                                        label="filters.filDestino"
                                        v-validate="'required_object'"
                                        :error="errors.first('rota.destino' + index)"
              />

              <!-- Taxas -->
                <div class="col-12 px-2">
                  <label>{{ $t('form.tarifario_lcl.section.taxas') }}</label>
                  <div class="row">
                    <div class="col-12">
                      <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                              <th>
                                Taxa Logística
                              </th>
                              <th>
                                Cbm mínimo
                              </th>
                              <th>
                                Cbm Máximo
                              </th>
                              <th>
                                Tipo Pagamento
                              </th>
                              <th>
                                Moeda. Pag.
                              </th>
                              <th>
                                Valor Pag. Unit.
                              </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="taxa in getTaxas(rota.taxas)" v-bind:key="taxa.id">
                              <td>{{ taxa.taxa.nome }}</td>
                              <td>{{ $util.formatNumber(taxa.cbmMinimo) }}</td>
                              <td>{{ $util.formatNumber(taxa.cbmMaximo) }}</td>
                              <td>{{ taxa.tipoPagamento }}</td>
                              <td>{{ taxa.moedaPagamento.sigla }}</td>
                              <td>{{ $util.formatNumber(taxa.valorPagamentoUnitario) }}</td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Submit -->
        <div class="col-12">
          <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">Voltar para lista</button>
        </div>

      </div>
    </form>
  </div>
</template>

<script>
// Components
import CustomInput from '@/components/Forms/CustomInput.vue'
import CustomSwitch from '@/components/Forms/CustomSwitch.vue'
import ArmadorField from '@/components/Forms/ArmadorField'
import OriginDestinationField from '@/components/Forms/OriginDestinationField'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import TaxLogisticService from '@/services/TaxLogisticService'
import TarifarioMaritimoService from '@/services/TarifarioMaritimoService'

export default {
  name: 'TariffLCLForm',
  metaInfo () {
    return {
      titleTemplate: this.$route.meta.titleText
    }
  },
  data () {
    return {
      tempOptions: [
        {
          id: 100,
          nome: 'Aarhus',
          sigla: 'DKAAR',
          pais: 'Dinamarca'
        },
        {
          id: 62,
          nome: 'Abidjan',
          sigla: 'CIABJ',
          pais: 'Costa do Marfim'
        }
      ],
      formData: {
        tipoOperacao: {
          id: 1,
          nome: 'Exportação'
        },
        numero: null,
        armador: null,
        contratoFrete: {
          numeroContrato: null
        },
        dataInicioVigencia: null,
        dataValidade: null
      },
      formErrors: {},
      isLoading: true,
      fullPage: true,
      // List of options to select on select input
      submitted: false,
      tipoOperacaoList: [
        {
          id: 1,
          nome: 'Exportação'
        },
        {
          id: 2,
          nome: 'Importação'
        }
      ]
    }
  },
  components: {
    CustomSwitch,
    Loading,
    CustomInput,
    ArmadorField,
    OriginDestinationField
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    let _this = this
    _this.isLoading = true

    // If is edit populate values
    let id = this.$route.params.id

    if (id) {
      TarifarioMaritimoService.getLcl(id).then((res) => {
        let tarifarioLcl = res.data

        _this.formData = Object.assign({}, tarifarioLcl)

        if (tarifarioLcl.dataInicioVigencia) {
          _this.formData.dataInicioVigencia = new Date(tarifarioLcl.dataInicioVigencia)
        }

        if (tarifarioLcl.dataValidade) {
          _this.formData.dataValidade = new Date(tarifarioLcl.dataValidade)
        }

        _this.formData.rotas = []

        _this.formData.rotas = tarifarioLcl.rotas
        _this.isLoading = false
      })
    } else {
      _this.isLoading = false
    }
  },
  methods: {
    getTaxas (taxas) {
      let taxasList = []

      for (let taxaIndex in taxas) {
        let taxa = taxas[taxaIndex]
        taxasList.push(taxa)

        for (let sobretaxaIndex in taxa.sobretaxas) {
          let sobretaxa = taxa.sobretaxas[sobretaxaIndex]
          taxasList.push(sobretaxa)
        }
      }

      return taxasList
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'TariffLclIndex' })
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('maerskTaxesForm').then(valid => {
        if (valid) {
          _this.isLoading = true
          let data = Object.assign({}, _this.formData)

          data.idtaxaLogistica = data.idtaxaLogistica.id

          let id = this.$route.params.id

          if (id) {
            TaxLogisticService.putTaxa(id, data).then(response => {
              _this.$router.push({ name: 'TariffLclIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            TaxLogisticService.postTaxa(data).then(response => {
              _this.$router.push({ name: 'TariffLclIndex' })
            }).finally(() => {
              _this.isLoading = false
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
</style>
